import React, { useState, useEffect, useCallback, useRef } from 'react'
import { 
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format, 
  isSameDay, 
  parseISO, 
  isBefore, 
  isAfter, 
  isMonday, 
  isTuesday, 
  isWednesday, 
  isThursday, 
  isFriday, 
  isSaturday, 
  isSunday,
  addMonths,
  subMonths,
  isSameMonth,
  getDay
} from 'date-fns'
import { es } from 'date-fns/locale'
import Button from '../ScheduleRefactorize/button';
import ScrollArea  from "./scrollarea";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import useToast from '../ScheduleRefactorize/useToast';

import './CalendarComponentStyle.css';
import HeaderComponent from '../Header/HeaderComponent';
import withAuthAndVisibility from '../UserAuth';
import { Props, UserSchedueleData } from '../AppPresenter';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface PropsType {
  signOut: () => void;
  props: Props;
  onSave: (data: UserSchedueleData[]) => void;
}

type ReservaUsuario = {
  hours: string[];
  id: number;
  user: string;
  table: string[];
}

type ReservaGeneral = {
  id: string;
  date: string;
  [key: string]: string;
}

type UserData = {
  _firstName: string;
  _lastName: string;
  _userStatus: string;
  _email: string;
  _schedule: string;
  _active: boolean | null;
  _activeDays: number;
  _limitDate: Date;
  _userType: number;
  _startDate: Date;
  _phoneNumber: string;
}

type HorasComponentProps = {
  fecha: Date; // Explicitly type fecha as Date
  reservasUsuario: ReservaUsuario[];
  reservasGenerales: ReservaGeneral[];
  onReserva: (fecha: Date, horaId: string) => void;
  diasRestantes: number;
  userEmail: string;
  props: any;
};

const HORAS_DISPONIBLES_LUNES_JUEVES = [
  { id: 'cluster0506', label: '5:00-6:00 a.m.' },
  { id: 'cluster0607', label: '6:00-7:00 a.m.' },
  { id: 'cluster0708', label: '7:00-8:00 a.m.' },
  { id: 'cluster0809', label: '8:00-9:00 a.m.' },
  { id: 'cluster1617', label: '4:00-5:00 p.m.' },
  { id: 'cluster1718', label: '5:00-6:00 p.m.' },
  { id: 'cluster1819', label: '6:00-7:00 p.m.' },
  { id: 'cluster1920', label: '7:00-8:00 p.m.' },
  { id: 'cluster2021', label: '8:00-9:00 p.m.' },
];

const HORAS_DISPONIBLES_VIERNES = [
  { id: 'cluster0506', label: '5:00-6:00 a.m.' },
  { id: 'cluster0607', label: '6:00-7:00 a.m.' },
  { id: 'cluster0708', label: '7:00-8:00 a.m.' },
  { id: 'cluster0809', label: '8:00-9:00 a.m.' },
  { id: 'cluster1617', label: '4:00-5:00 p.m.' },
  { id: 'cluster1718', label: '5:00-6:00 p.m.' },
  { id: 'cluster1819', label: '6:00-7:00 p.m.' },
  { id: 'cluster1920', label: '7:00-8:00 p.m.' },
]

const HORAS_DISPONIBLES_SABADO = [
  { id: 'cluster0506', label: '7:30-8:30 a.m.' },
  { id: 'cluster0607', label: '8:30-9:30 a.m.' },
  { id: 'cluster0708', label: '9:30-10:30 a.m.' },
]

const HORAS_DISPONIBLES_SABADO_FLAG = [
  { id: 'cluster0506', label: '7:30-8:30 a.m.' },
  { id: 'cluster0607', label: '8:30-9:30 a.m.' },
]

const HORAS_DISPONIBLES_VIERNES_VALLEY = [
  { id: 'cluster0708', label: '7:00-8:00 a.m.' },
  { id: 'cluster0809', label: '8:00-9:00 a.m.' },
  { id: 'cluster1617', label: '4:00-5:00 p.m.' },
];

const HORAS_DISPONIBLES_LUNES_JUEVES_VALLEY = [
  { id: 'cluster0809', label: '8:00-9:00 a.m.' },
  { id: 'cluster1617', label: '4:00-5:00 p.m.' },
  { id: 'cluster2021', label: '8:00-9:00 p.m.' },
];

const MAX_RESERVAS_POR_CLASE = 12;
const MAX_RESERVAS_POR_CLASE_FLAG = 12;
const targetDate = new Date('2024-09-26T00:00:00-05:00');

function formatDate(dateItemId: number) {
  const dateToSave = new Date(dateItemId);

  const year = dateToSave.getFullYear();
  const month = String(dateToSave.getMonth() + 1).padStart(2, '0');
  const day = String(dateToSave.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function mergeObjects(obj1: any, obj2: any): any {
  const result = { ...obj1 }; // Start with obj1 as the base

  for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
          // Skip merging for "id" and "date"
          if (key === "id" || key === "date") continue;

          if (obj2[key] !== "") {
              // If both obj1 and obj2 have non-empty values, join them with a comma
              if (result[key] !== "") {
                  result[key] = `${result[key]},${obj2[key]}`;
              } else {
                  // Otherwise, just take the non-empty value from obj2
                  result[key] = obj2[key];
              }
          }
      }
  }

  return result;
}

function capitalizeFirstLetter(string: string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const HorasComponent: React.FC<HorasComponentProps> = ({
  fecha,
  reservasUsuario,
  reservasGenerales,
  onReserva,
  diasRestantes,
  userEmail,
  props,
}) => {
  const [cargandoHoras, setCargandoHoras] = useState<{ [key: string]: boolean }>({})

  const [ checkUsers, setCheckUsers ] = useState({
    status: false,
    hour: ''
  });

  const modalRef = useRef<HTMLDivElement | null>(null); // Ref for the modal  

  const obtenerHorariosDisponibles = (fecha: Date) => {
    let horasDisponibles = HORAS_DISPONIBLES_LUNES_JUEVES
    if (props.user._userType === '3') {
      horasDisponibles = HORAS_DISPONIBLES_LUNES_JUEVES_VALLEY;
    }
    if (isFriday(fecha)) {
      horasDisponibles = HORAS_DISPONIBLES_VIERNES;
      if (props.user._userType === '3') {
        horasDisponibles = HORAS_DISPONIBLES_VIERNES_VALLEY;
      }
    } else if (isSaturday(fecha)) {
      horasDisponibles = fecha.toDateString() === targetDate.toDateString() ? HORAS_DISPONIBLES_SABADO_FLAG : HORAS_DISPONIBLES_SABADO
    }

    const reservaGeneral = reservasGenerales.find(r => formatDate(parseInt(r.id)) === format(fecha, 'yyyy-MM-dd'));
    const reservaUsuario = reservasUsuario.find(r => formatDate(r.id)  === format(fecha, 'yyyy-MM-dd'));

    const reservaGeneralAll = reservasGenerales.filter(r => formatDate(parseInt(r.id))=== format(fecha, 'yyyy-MM-dd'));
 
    let result = { ...reservaGeneralAll[0] }; // Start with the first object as the base

    // Merge each object in the reservaGeneralAll into the result
    for (let i = 1; i < reservaGeneralAll.length; i++) {
        result = mergeObjects(result, reservaGeneralAll[i]);
    }
    
    const ahora = new Date();

    return horasDisponibles.map(hora => {
      let usuariosReservados: any = result ? (result[hora.id] ? result[hora.id].split(',').filter(Boolean) : []) : []
      const reservadoPorUsuario = reservaUsuario ? reservaUsuario.hours.includes(hora.id) : false;
      
      // if (reservadoPorUsuario && !usuariosReservados.includes(userEmail)) {
      //   usuariosReservados = [...usuariosReservados, userEmail]
      // }

      usuariosReservados = usuariosReservados.map((user: any) => {
        const userFiltered = props.allUsers.filter((u: any) => u.email === user);
        
        return {
          name: capitalizeFirstLetter(userFiltered[0].firstName.split(" ")[0].toLowerCase()) + " " + capitalizeFirstLetter(userFiltered[0].lastName.split(" ")[0].toLowerCase()),
          email: user
        }
      })

      const cantidadReservas = usuariosReservados.length
      const horaClase = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), parseInt(hora.id.slice(7, 9)), 0)
    
      const oneHourBeforeSpecificTime = new Date(horaClase);
      oneHourBeforeSpecificTime.setHours(horaClase.getHours() - 1);

      // Check if horaClase is between oneHourBeforeSpecificTime and specificTime
      let isBetween = ahora <= oneHourBeforeSpecificTime;

      // if (fecha.toDateString() === targetDate.toDateString()) {
      //   const oneHourBeforeSpecificTime = new Date(horaClase);
      //   oneHourBeforeSpecificTime.setHours(horaClase.getHours() + 2);
      //   isBetween = ahora <= oneHourBeforeSpecificTime;
      // }
      
      //console.log(cantidadReservas, targetDate, (fecha.toDateString() === targetDate.toDateString() ? MAX_RESERVAS_POR_CLASE_FLAG : MAX_RESERVAS_POR_CLASE), diasRestantes >= 0,  isBetween);
      
      const disponible = cantidadReservas < (fecha.toDateString() === targetDate.toDateString() ? MAX_RESERVAS_POR_CLASE_FLAG : MAX_RESERVAS_POR_CLASE) && diasRestantes >= 0 && isBetween
      
      return {
        ...hora,
        cantidadReservas,
        disponible,
        usuariosReservados,
        reservadoPorUsuario
      }
    })
  }

  const reservarClase = (horaId: string) => {
    const reservaExistente = reservasUsuario.find(r => formatDate(r.id) === format(fecha, 'yyyy-MM-dd') && r.hours.includes(horaId));
   
    if (!reservaExistente && diasRestantes <= 0) {
      return
    }

    setCargandoHoras(prev => ({ ...prev, [horaId]: true }))
    onReserva(fecha, horaId)
    setCargandoHoras(prev => ({ ...prev, [horaId]: false }))
  }
  const handleCheckUsers = (hora: any) => {
    setCheckUsers({
      status: !checkUsers.status,
      hour: hora.id
    })
    
  }
  
  useEffect(() => {
    
    const handleClickOutside = (event: MouseEvent) => {

      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setCheckUsers({ status: false, hour: '' });
        
      }
    };

    if (checkUsers.status) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [checkUsers.status]);

  return (
    <ScrollArea>
    <h2 className="schedule_hours_container_heading">{format(fecha, 'PPPP', { locale: es })}</h2>
    <div className="schedule_hours_container">
      {obtenerHorariosDisponibles(fecha).map((hora) => {
        return(
        <div key={hora.id} className={`time_slot${hora.reservadoPorUsuario ? '_reserved' : ''}`}>
          <span className="time_slot_content">
            {hora.label}
          </span>
          <div className="time_slot_content">
            <span className="info_text">
              {hora.cantidadReservas}/{(fecha.toDateString() === targetDate.toDateString() ? MAX_RESERVAS_POR_CLASE_FLAG : MAX_RESERVAS_POR_CLASE)}
            </span>
            <Button variant="default" className="button_users_list"
              onClick={() => handleCheckUsers(hora)}
            >
              <PeopleOutlineIcon />
            </Button>
            { checkUsers.status && checkUsers.hour === hora.id && hora.usuariosReservados.length > 0 &&
              <div className='users_modal' >
                <div className='users_modal_inner' ref={modalRef}>
                  <div className='users_modal_inner_title'>
                    <p >Usuarios Reservados</p>
                    <p>({hora.label})</p>
                  </div>
                  <div>
                      {hora.usuariosReservados.map((usuario: any, index: any) => (
                        <div key={index}>{(index + 1) + ". " +usuario.name}</div>
                      ))}
                  </div>
                </div>
            </div>
            }
           
            <Button
              onClick={() => reservarClase(hora.id)}
              disabled={(!hora.disponible) || cargandoHoras[hora.id] || (fecha.toDateString() === targetDate.toDateString() && hora.id === 'cluster1617')}
              className={`btn${hora.reservadoPorUsuario ? '_secondary' : '_default'}`}
            >
              {cargandoHoras[hora.id] ? 'Actualizando...' : hora.reservadoPorUsuario ? 'Cancelar' : 'Reservar'}
            </Button>
          </div>
        </div>
      )})}
    </div>
  </ScrollArea>
  )
}

const CalendarComponent : React.FC<PropsType> = ({ signOut, props, onSave }) => {

  const [userData, setUserData] = useState<UserData | null>(null)
  const [reservasUsuario, setReservasUsuario] = useState<ReservaUsuario[]>([])
  const [reservasGenerales, setReservasGenerales] = useState<any>([])
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(isSunday(new Date()) ? date : new Date())
  const [mesActual, setMesActual] = useState(new Date())
  const [guardando, setGuardando] = useState(false)
  const [diasRestantes, setDiasRestantes] = useState(0)
  const [fechaLimite, setFechaLimite] = useState<Date | null>(null)
  const [cambiosPendientes, setCambiosPendientes] = useState(false)
  const [reservasOriginales, setReservasOriginales] = useState<ReservaUsuario[]>([])
  const [loadingSignOut, setLoadingSignOut] = useState(props.loadingSignOut);
  const { showToast } = useToast()

  const navigate = useNavigate()

  useEffect(() => {
    setReservasGenerales(props.allSchedule)
  },[props.allSchedule])

  useEffect(() => {
    setLoadingSignOut(props.loadingSignOut);
  }, [props.loadingSignOut]);

  useEffect(() => {
    if (props.user?._userStatus !== 'activeUser') {
      navigate('/signin');
    }
  }, [props.user?._userStatus]);

  useEffect(() => {
    
    if (props.user && props.user._userStatus !== "noActiveUser") {

      const loadedUserData: UserData = {
        _firstName: props.user._firstName,
        _lastName: props.user._lastName,
        _userStatus: props.user._userStatus,
        _email: props.user._email,
        _schedule: props.user._schedule,
        _active: props.user._active,
        _activeDays: props.user._activeDays,
        _limitDate: props.user._limitDate,
        _userType: props.user._userType,
        _startDate: props.user._startDate,
        _phoneNumber: props.user._phoneNumber
    };
      setUserData(loadedUserData)

      const scheduleData: ReservaUsuario[] = JSON.parse(loadedUserData._schedule)
      setReservasUsuario(scheduleData)
      setReservasOriginales(scheduleData)
      
      const startDate = parseISO(loadedUserData._startDate as unknown as string | '')
      const limitDate = parseISO(loadedUserData._limitDate as unknown as string | '')

      // Filter schedule data to include only entries on or after startDate
      const filteredScheduleData = scheduleData.filter(entry => {
        return new Date(entry.id) >= startDate && entry.hours.length > 0;
      });

      // Calculate used days
      const usedDays = filteredScheduleData.length;
    
      const remainingDays = Math.max(0, loadedUserData._activeDays - usedDays)
      setDiasRestantes(remainingDays)
      setFechaLimite(limitDate)

      setReservasGenerales(props.allSchedule)
    } else {
      navigate('/signin')
    }
    
  }, [])

  useEffect(() => {
    setGuardando(props.loading);
    if (props.actionMessage === 'scheduleUpdated with 200') {
      showToast({
        title: "Cambios guardos",
        description: "Sus cambios se han guardado con exito!",
        variant: "success",
      })
    }
    
  }, [props.loading]);

  useEffect(() => {
    const hayCambios = JSON.stringify(reservasUsuario) !== JSON.stringify(reservasOriginales)
    setCambiosPendientes(hayCambios)
  }, [reservasUsuario, reservasOriginales])

  const tieneReserva = useCallback((fecha: Date) => {
    return reservasUsuario.some(reserva => {
      return isSameDay(new Date(reserva.id), fecha) && reserva.hours.length > 0;
    })
  }, [reservasUsuario])

  const handleReserva = (fecha: Date, horaId: string) => {
    if (!userData) return;

    setReservasUsuario(prevReservas => {
      const reservaExistente = prevReservas.find(r => formatDate(r.id) === format(fecha, 'yyyy-MM-dd'));
   
      let newReservas;
      
      if (reservaExistente) {
        if (reservaExistente.hours.includes(horaId)) {
          newReservas = prevReservas.map(r => 
            formatDate(r.id) === format(fecha, 'yyyy-MM-dd')
              ? { ...r, hours: r.hours.filter(h => h !== horaId) }
              : r
          )
        } else {
          newReservas = prevReservas.map(r => 
            formatDate(r.id) === format(fecha, 'yyyy-MM-dd')
              ? { ...r, hours: [...r.hours, horaId] }
              : r
          )
        }
      } else {
        if (diasRestantes > 0) {
          newReservas = [...prevReservas, {
            hours: [horaId],
            id: fecha.setHours(0,0,0,0),
            user: userData._email,
            table: ["1,2"]
          }]
        } else {
          newReservas = prevReservas
        }
      }

      const filteredScheduleData = newReservas.filter(entry => {
        return new Date(entry.id).setHours(0,0,0,0) >= new Date(userData._startDate).setHours(0,0,0,0) && entry.hours.length > 0;
      });

      // Calculate used days
      const usedDays = filteredScheduleData.length;
    
      const remainingDays = Math.max(0, userData._activeDays - usedDays)
      setDiasRestantes(remainingDays);
      
      return newReservas
    })

    setReservasGenerales((prevReservas: any) => {
      const fechaFormateada = format(fecha, 'yyyy-MM-dd');
      const reservaExistente = prevReservas.find((r : any)=> formatDate(parseInt(r.id))=== fechaFormateada);
      
      if (reservaExistente) {
        return prevReservas.map((r : any) => {
          if (parseInt(r.id) === fecha.setHours(0,0,0)) {
            const usuariosActuales = r[horaId] ? r[horaId].split(',').filter(Boolean) : [];
            const usuarioIndex = usuariosActuales.indexOf(userData._email);
            if (usuarioIndex > -1) {
              usuariosActuales.splice(usuarioIndex, 1)
            } else {
              usuariosActuales.push(userData._email)
            }
            return { ...r, [horaId]: usuariosActuales.join(',') }
          }
          return r
        })
      } else {
        return [...prevReservas, {
          id: fecha.setHours(0,0,0,0).toString(),
          date: fechaFormateada,
          [horaId]: userData._email
        }]
      }
    })
  }

  const guardarReservas = async () => {
    if (!userData) return;

    setGuardando(true)
    try {

      onSave(reservasUsuario);

      const dataToSend = {
        usuario: userData._email,
        reservasUsuario,
        reservasGenerales
      }
      setReservasOriginales(reservasUsuario)
      setCambiosPendientes(false)
    } catch (error) {
      showToast({
        title: "Error al guardar",
        description: "No se pudieron guardar las reservas. Por favor, intenta de nuevo.",
        variant: "destructive",
      })
    } finally {
      setGuardando(false)
    }
  }

  const esDiaSeleccionable = useCallback((dia: Date) => {
    const hoy = new Date()
    return (isAfter(dia, hoy) || isSameDay(dia, hoy)) && 
           (isBefore(dia, fechaLimite!) || isSameDay(dia, fechaLimite!)) &&
           (isMonday(dia) || isTuesday(dia) || isWednesday(dia) || isThursday(dia) || isFriday(dia) || isSaturday(dia))
  }, [fechaLimite])

  const diasCalendario = eachDayOfInterval({
    start: startOfMonth(mesActual),
    end: endOfMonth(mesActual)
  }).filter(day => !isSunday(day));

  const cambiarMes = (direccion: 'anterior' | 'siguiente') => {
    setMesActual(mes => direccion === 'anterior' ? subMonths(mes, 1) : addMonths(mes, 1))
  }

  const primerDiaSemana = (getDay(startOfMonth(mesActual)) + 6) % 7
  const diasAnteriores = Array(primerDiaSemana).fill(null)

  if (!userData) {
    return <div>Cargando...</div>
  }

  return (
    <div className="">
      <HeaderComponent />
      <div className='schedule_container'>
        <div className="schedule_user_info">
        {
            ((props.user?._userType as unknown as string) === '0') &&
            <>
            <span className="text-sm font-medium">
                  Días restantes: {diasRestantes}
                </span>
            </>
          }
          {
          ((props.user?._userType as unknown as string) === '2' || (props.user?._userType as unknown as string) === '3') &&
            <>
            <span className="text-sm font-medium flex items-center">
                  Fecha límite: {fechaLimite ? format(fechaLimite, 'dd/MM/yyyy') : 'N/A'}
                </span>
            </>
          }
          
          
        </div>
        <div className="schedule_arrows_container">
          <Button onClick={() => cambiarMes('anterior')} variant="arrow" disabled={isBefore(startOfMonth(mesActual), new Date())}>
            <ArrowBackIosIcon />
            
          </Button>
          <h2 style={{fontSize: '18px'}}>{capitalizeFirstLetter(format(mesActual, 'MMMM', { locale: es }))}</h2>
          <Button onClick={() => cambiarMes('siguiente')} variant="arrow"  disabled={isAfter(endOfMonth(mesActual), fechaLimite!)}>
            
            <ArrowForwardIosIcon />
          </Button>
        </div>
        <div className="grid_container">
          {['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'].map(dia => (
            <div key={dia} className="grid_item">{dia}</div>
          ))}
          {diasAnteriores.map((_, index) => (
            <div key={`empty-${index}`} className="empty-cell" />
          ))}
          {diasCalendario.map((dia, index) => (
            <button
              key={index}
              className={`btn${
                !isSameMonth(dia, mesActual) ? '_disabled' :
                !esDiaSeleccionable(dia) ? '_disabled' : 
                tieneReserva(dia) ? '_reserved' : ''
              } ${isSameDay(dia, fechaSeleccionada) ? ` selected${tieneReserva(dia) ? '_reserved' : ''}` : ''}`}
              onClick={() => esDiaSeleccionable(dia) && setFechaSeleccionada(dia)}
              disabled={!esDiaSeleccionable(dia) || !isSameMonth(dia, mesActual)}
            >
              <div className="text-center">
                <div className={
                  `button_text_day${new Date().setHours(0,0,0,0) === dia.setHours(0,0,0,0) ? `_actualday${isSameDay(dia, fechaSeleccionada) ? '_selected' : ''}` : ''}`}>
                  {format(dia, 'd')}
                </div>
              </div>
            </button>
          ))}
        </div>
        <Button
          onClick={guardarReservas}
          disabled={guardando || !cambiosPendientes}
          variant={cambiosPendientes ? "active" : "outline"}
        >
          {guardando ? 'Guardando...' : cambiosPendientes ? 'Guardar Cambios' : 'No hay cambios para guardar'}
        </Button>
      </div>
      <div className="w-full lg:w-1/3">
        <HorasComponent 
          fecha={fechaSeleccionada} 
          reservasUsuario={reservasUsuario}
          reservasGenerales={reservasGenerales}
          onReserva={handleReserva}
          diasRestantes={diasRestantes}
          userEmail={userData._email}
          props={props}
        />
      </div>
      <button className="signout_button_item" onClick={signOut}>
            {
                !loadingSignOut ? "Salir" : 
                <Box sx={{ display: 'flex'}}>
                  <CircularProgress size="16px" style={{color: "gray"}} />
                </Box>
              }
          </button>
    </div>
  )
}

export default withAuthAndVisibility(CalendarComponent);