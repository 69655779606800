import { GetCurrentUserService, SignInService } from "../useCases";
import { User } from "../entities";
import { confirmSignUp, fetchUserAttributes, signIn, signUp, signOut, resendSignUpCode, resetPassword, confirmResetPassword } from 'aws-amplify/auth';

import { Either, left, right } from '../../utils/CheckResponseSide';
import { SignUpService } from "../useCases/signUpInteractor";
import { SendConfirmationService } from "../useCases/sendConfirmationInteractor";
import { SignOutService } from "../useCases/signOutInteractor";
import { ResendEmailCodeService } from "../useCases/resendEmailCodeInteractor";
import { sendRecoveryPasswordService } from "../useCases/recoveryPasswordInteractor";

const userEmpty = {
  _id: "",
  _firstName: "",
  _lastName: "",
  _userStatus: "",
  _email: "",
  _name: "",
  _schedule: JSON.stringify([{
    data: null
  }]),
  _active: null,
  _activeDays: 0,
  _limitDate: new Date(),
  _userType: 2,
  _startDate: new Date(),
  _phoneNumber: ""
}

export class AWSServices implements 
    SignInService,
    GetCurrentUserService,
    SignUpService,
    SendConfirmationService,
    SignOutService ,
    ResendEmailCodeService,
    sendRecoveryPasswordService
  {

  //If logging will return cognito user values, if not will return user with userStatus to confirm or register needed  
  async signInWithCredential(username: string, password: string): Promise<Either<Error, User>> {    
      try {
          const isSignedIn = await signIn({ username, password });
          if (isSignedIn.nextStep.signInStep === "CONFIRM_SIGN_UP") {
            userEmpty._userStatus = "userNeedConfirmation";
            return right(userEmpty as unknown as User);
          } else {
            userEmpty._userStatus = "activeUser";
            return right(userEmpty as unknown as User);
          }
      } catch (error) {
        userEmpty._userStatus = Error(String(error)).message;
        return right(userEmpty as unknown as User);
      }
  }

  async signOutUser(): Promise<Either<Error, User>> {
      try {
        await signOut();
        userEmpty._userStatus = "noActiveUser";
        return right(userEmpty as unknown as User);
      } catch (error) {
        userEmpty._userStatus = Error(String(error)).message;
        return right(userEmpty as unknown as User);
      }
  }

  async sendEmailConfirmationCode(username: string, confirmationCode: string): Promise<Either<Error, User>>  {
    try {
        const isSignUpComplete = await confirmSignUp({
          username,
          confirmationCode
        });
        const user = new User(
          userEmpty._id,
          userEmpty._firstName,
          userEmpty._lastName,
          userEmpty._email,
          "noActiveUser",
          userEmpty._schedule,
          userEmpty._active,
          userEmpty._activeDays,
          userEmpty._limitDate,
          userEmpty._userType,
          userEmpty._startDate,
          userEmpty._phoneNumber
        )
        return right(user);
        
    } catch (error) {
      const user = new User(
        userEmpty._id,
        userEmpty._firstName,
        userEmpty._lastName,
        userEmpty._email,
        "noActiveUser",
        userEmpty._schedule,
        userEmpty._active,
        userEmpty._activeDays,
        userEmpty._limitDate,
        userEmpty._userType,
        userEmpty._startDate,
        userEmpty._phoneNumber
      )
      return right(user);
    }
  }

  //This is the user with cognito values, then we need to call real values from DB
  async getActualCurrentUser(): Promise<Either<Error, User>>  {
    try {
        const getActualUser = await fetchUserAttributes();
        const user: User = new User(
          getActualUser.sub as string,
          getActualUser.given_name as string,
          getActualUser.family_name as string,
          getActualUser.email as string,
          "activeUser",
          userEmpty._schedule,
          userEmpty._active,
          userEmpty._activeDays,
          userEmpty._limitDate,
          userEmpty._userType,
          userEmpty._startDate,
          userEmpty._phoneNumber
        );
        return right(user);
        
    } catch (error) {
      const user = new User(
        userEmpty._id,
        userEmpty._firstName,
        userEmpty._lastName,
        userEmpty._email,
        "noActiveUser",
        userEmpty._schedule,
        userEmpty._active,
        userEmpty._activeDays,
        userEmpty._limitDate,
        userEmpty._userType,
        userEmpty._startDate,
        userEmpty._phoneNumber
      )
      return right(user);
    }
  }

  async signUpUserRegistration(
      username: string,
      password: string,
      email: string,
      phone_number: string,
      given_name: string,
      family_name: string
    ): Promise<Either<Error, User>>  {
      try {
        await signUp({
          username,
          password,
          options: {
            userAttributes: {
              email,
              phone_number,
              given_name,
              family_name
            },
            autoSignIn: true
          }
        });
        const user = new User(
          userEmpty._id,
          userEmpty._firstName,
          userEmpty._lastName,
          userEmpty._email,
          "userNeedConfirmation",
          userEmpty._schedule,
          userEmpty._active,
          userEmpty._activeDays,
          userEmpty._limitDate,
          userEmpty._userType,
          userEmpty._startDate,
          userEmpty._phoneNumber
        )
        return right(user);
      } catch (error) {
        return left(error instanceof Error ? error : new Error(String(error)));
      }
  }

  async resendEmailCode(email: string): Promise<Either<Error, User>>  {
    const input = {
      username: email
    };
    try {
      await resendSignUpCode(input);
      const user = new User(
        userEmpty._id,
        userEmpty._firstName,
        userEmpty._lastName,
        userEmpty._email,
        "userNeedConfirmation",
        userEmpty._schedule,
        userEmpty._active,
        userEmpty._activeDays,
        userEmpty._limitDate,
        userEmpty._userType,
        userEmpty._startDate,
        userEmpty._phoneNumber
      )
      return right(user);
    } catch (error) {
      return left(error instanceof Error ? error : new Error(String(error)));
    }
  }

  async sendEmailRecoveryCode(email: string): Promise<Either<Error, User>>  {
      try {
        await resetPassword({
          username: email
        });
        const user = new User(
          userEmpty._id,
          userEmpty._firstName,
          userEmpty._lastName,
          userEmpty._email,
          "userNeedRecovery",
          userEmpty._schedule,
          userEmpty._active,
          userEmpty._activeDays,
          userEmpty._limitDate,
          userEmpty._userType,
          userEmpty._startDate,
          userEmpty._phoneNumber
        )
        return right(user);
      } catch (error) {
        return left(error instanceof Error ? error : new Error(String(error)));
      }
  }

  async sendPasswordRecoveryCode(email: string, code: string, password: string): Promise<Either<Error, User>>  {
   
      try {
        await confirmResetPassword({
          username: email,
          newPassword: password,
          confirmationCode: code,
        });
        const user = new User(
          userEmpty._id,
          userEmpty._firstName,
          userEmpty._lastName,
          userEmpty._email,
          "userRecoveryPasswordSent",
          userEmpty._schedule,
          userEmpty._active,
          userEmpty._activeDays,
          userEmpty._limitDate,
          userEmpty._userType,
          userEmpty._startDate,
          userEmpty._phoneNumber
        )
        return right(user);
      } catch (error) {
        return left(error instanceof Error ? error : new Error(String(error)));
      }
      
  }

}
